import { IFontMap } from "src/components/themes/IFontMap";
import { IPalette } from "src/components/themes/IPalette";
import { ITheme } from "src/components/themes/ITheme";


const palette: IPalette = {
	sgColor: "#2c2236",
	gullHovedfarge: "#afa376",
	gullLys: "#d0c49a",
	lillaHovedfarge: "#352761",

	lilla1: "#9177ff",
	lilla2: "#812de2",
	lilla4: "#231a41",

	lillaMørkHovedfarge: "#0f0b1c",
	knappFarge1: "#9d95ff",
	knappAktivFarge: "#aaf5fb",
	hvitHovedfarge: "#ebebeb",
	turkis2: "#08cdf7",
	turkis3: "#46bdcc",
	grønn: "#46cc82",
	peach: "#ffa391",
	rosa: "#bc367f",
    lyslilla: "#C0BDFE",
	rød: "#791130",
	oransje: "#ff5341",
	gul1: "#ffff77",
	gul2: "#ffd460",
	blå1: "#5741d9",
	blå2: "#4f42d8", // denne er feil i design doc. Dette er riktig kode
	lysGrå: "#adadaf",
	mediumGrå: "#686876",
	mørkGrå: "#3e3e48",

	// Buttons from top on page 9 i design spec (https://xd.adobe.com/view/2af3c926-5623-46fe-aa54-9c3f2aae352f-7fa0/screen/da1c0d94-bf83-460b-a473-11946b126f63/specs/)
	buttonGradient1: `transparent linear-gradient(var(--gr1Rotation, 103deg), #9177FF 0%, #08CDF7 29%, #DE96B2 68%, #9177FF 100%) 0% 0% no-repeat padding-box`,
	buttonGradient2: `transparent linear-gradient(var(--gr2Rotation, 103deg), #AAE6FB 0%, #FFC8BE 37%, #F7F7EC 70%, #B3ADFF 100%) 0% 0% no-repeat padding-box`,
	buttonGradient3: `transparent linear-gradient(var(--gr3Rotation, 90deg), #AAE6FB 0%, #9D95FF 100%) 0% 0% no-repeat padding-box`,

    // gradient from https://xd.adobe.com/view/aff6b94b-456c-40d0-9afb-ac43be6e9eeb-faa5/specs/
    gradient1: `transparent linear-gradient(142deg, #CFBFFF 0%, #8CB7FC 100%) 0% 0% no-repeat padding-box`,

    conicGradient: "transparent conic-gradient(from 117deg at 50% 50%, #B3ADFF 0.00%, #AAE6FB 16.57%, #FFC8BE 41.20%, #F8AED6 68.79%, #B3ADFF 100.00%) 0% 0% no-repeat padding-box",

	sgGradientStops: [{color: "#cfbfff", stop: "0%"}, {color: "#8cb7fc", stop: "100%"}],
	sgGradient: `transparent linear-gradient(var(--gr1Rotation, 270deg), #cfbfff 0%, #8cb7fc 100%) 0% 0% no-repeat padding-box`
};

const Fonts: IFontMap = {
	tittelXL: {
		size: "54px",
		weight: "bold"
	},
	tittelL: {
		size: "44px",
		weight: "bold"
	},
	tittelM: {
		size: "34px",
		weight: "bold"
	},
	tittelS: {
		size: "24px",
		weight: "bold"
	},
	tittelXS: {
		size: "18px",
		weight: "normal" // can also be bold
	},
	tittelXXS: {
		size: "16px",
		weight: "normal" // can also be bold
	},
	tittelXXXS: {
		size: "14px",
		weight: "normal" // can also be bold
	},


	ingressL: {
		size: "34px",
		weight: "bold"
	},
	ingressM: {
		size: "24px",
		weight: "bold"
	},
	ingressS: {
		size: "18px",
		weight: "normal" // can also be bold
	},


	bodytextXL: {
		size: "22px",
		weight: "normal" // can also be bold
	},
	bodytextL: {
		size: "20px",
		weight: "normal" // can also be bold
	},
	bodytextM: {
		size: "18px",
		weight: "normal" // can also be bold
	},
	bodytextS: {
		size: "16px",
		weight: "normal" // can also be bold
	},

	buttontextL: {
		size: "18px",
		weight: "normal",// can also be bold
	},
	buttontextM: {
		size: "16px",
		weight: "normal",// can also be bold
	},
	buttontextS: {
		size: "14px",
		weight: "normal",// can also be bold
	},
	buttontextXS: {
		size: "12px",
		weight: "normal",// can also be bold
	}

};

const theme: ITheme = {
	maxContentWidth: 1640,
	maxContentPadding: "10px",
	posterAspectRatio: 848 / 600,
	mq: {
		desktop: "(min-width: 550px)",
		mobile: "(max-width: 549px)",
		desktopPlain: "min-width: 550px",
		mobilePlain: "max-width: 549px"
	},

	h1LineHeight: 1.42,

	palette: palette,

	generalAlpha: 0.95,
	missingPoster: "linear-gradient(-45deg, #eb1f59 0%, #502d61 100%)",

	bgColor: palette.lillaMørkHovedfarge,
	textColor: palette.hvitHovedfarge,
	textColorDark: palette.lillaMørkHovedfarge,
	kinoklubbColor: palette.gullLys,
	sgLogoColor: palette.gullHovedfarge,
	mainColor: palette.lillaHovedfarge,
	buttonColor: palette.lillaHovedfarge,
	sgColor: palette.lillaHovedfarge,
	easter: palette.gul1,
	fonts: Fonts,
	themeColor: "#690ab8",

	bottomMenuHeight: "55px",
	topMenuHeight: "55px",

    stdBorderRadius: "8px"


};

export default theme;